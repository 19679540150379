import React, { FunctionComponent } from 'react';
import clsx from 'clsx';
import styles from './product.module.css';
// @ts-ignore
import smartphone from './smartphone.svg';
// @ts-ignore
import box from './box.svg';
// @ts-ignore
import smallbox from './small_box.svg';
// @ts-ignore
import brick from './brick.svg';
// @ts-ignore
import satelliteA from './satellite_a.svg';
// @ts-ignore
import satelliteB from './satellite_b.svg';

interface Props {
  hovered: boolean;
}

const ProductTile: FunctionComponent<Props> = ({ hovered }) => {
  return (
    <span className={clsx(styles.wrapper, { [styles.hovered]: hovered })}>
      <img src={satelliteB} alt="" className={styles.satelliteB} />
      <img src={box} alt="" className={styles.box} />
      <img src={smallbox} alt="" className={styles.smallbox} />
      <img src={smartphone} alt="" className={styles.smartphone} />
      <img src={brick} alt="" className={styles.brick} />
      <img src={satelliteA} alt="" className={styles.satelliteA} />
    </span>
  );
};

export default ProductTile;
