import React, { FunctionComponent } from 'react';
import clsx from 'clsx';
import styles from './mvp.module.css';
// @ts-ignore
import rocket from './rocket.svg';
// @ts-ignore
import planet from './planet.svg';
// @ts-ignore
import satellite from './satellite.svg';
// @ts-ignore
import satelliteA from './small_satellite_a.svg';
// @ts-ignore
import satelliteB from './small_satellite_b.svg';

interface Props {
  hovered: boolean;
}

const MVPTile: FunctionComponent<Props> = ({ hovered }) => {
  return (
    <span className={clsx(styles.wrapper, { [styles.hovered]: hovered })}>
      <img src={satelliteA} alt="" className={styles.satelliteA} />
      <img src={satelliteB} alt="" className={styles.satelliteB} />
      <img src={planet} alt="" className={styles.planet} />
      <img src={satellite} alt="" className={styles.satellite} />
      <img src={rocket} alt="" className={styles.rocket} />
    </span>
  );
};

export default MVPTile;
