import React, { FunctionComponent } from 'react';
import clsx from 'clsx';
import styles from './team.module.css';
// @ts-ignore
import persons from './persons.svg';
// @ts-ignore
import ellipseA from './ellipse_a.svg';
// @ts-ignore
import ellipseB from './ellipse_b.svg';
// @ts-ignore
import satelliteA from './satellite_a.svg';
// @ts-ignore
import satelliteB from './satellite_b.svg';

interface Props {
  hovered: boolean;
}

const TeamTile: FunctionComponent<Props> = ({ hovered }) => {
  return (
    <span className={clsx(styles.wrapper, { [styles.hovered]: hovered })}>
      <img src={satelliteB} alt="" className={styles.satelliteB} />
      <img src={satelliteA} alt="" className={styles.satelliteA} />
      <img src={ellipseA} alt="" className={styles.ellipseA} />
      <img src={ellipseB} alt="" className={styles.ellipseB} />
      <img src={persons} alt="" className={styles.persons} />
    </span>
  );
};

export default TeamTile;
