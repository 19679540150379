import React, { FunctionComponent, useState } from 'react';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import clsx from 'clsx';
import { Link } from 'gatsby';
import styles from './tile.module.css';
import MVPTile from './ico/mvp';
import ProductTile from './ico/product';
import TeamTile from './ico/team';
import DigitalTile from './ico/digital';

interface Item {
  name: string;
  title: string;
  text: string;
  to: string;
}

interface Props {
  list: ReadonlyArray<Item>;
  className?: string;
}

interface Images {
  [key: string]: FunctionComponent;
}

const Tiles: FunctionComponent<Props> = ({ list, className }) => {
  const [hovered, onChangeHovered] = useState<number | null>(null);
  const breakpoint = useBreakpoint();

  const images: Images = {
    // @ts-ignore
    'mvp-development': MVPTile,
    'product-development': ProductTile,
    'dedicated-team': TeamTile,
    'digital-transformation': DigitalTile,
  };

  // @ts-ignore
  return (
    <ul className={clsx(styles.list, className)}>
      {list.map((e, idx) => (
        <li
          key={e.name}
          className={styles.item}
          onMouseOver={() => !breakpoint.s && onChangeHovered(idx)}
          onMouseOut={() => !breakpoint.s && onChangeHovered(null)}
        >
          <Link to={e.to} className={styles.tile} data-icon={e.name}>
            {images[e.name] ? (
              // @ts-ignore
              <span className={styles.image}>
                {React.createElement(images[e.name], { hovered: hovered === idx })}
              </span>
            ) : null}
            <h3 className={styles.title}>{e.title}</h3>
            <p className={styles.text}>{e.text}</p>
          </Link>
        </li>
      ))}
    </ul>
  );
};

export default Tiles;
