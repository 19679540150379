import React, { FunctionComponent } from 'react';
import clsx from 'clsx';
import styles from './digital.module.css';
// @ts-ignore
import triangleA from './triangle_a.svg';
// @ts-ignore
import triangleB from './triangle_b.svg';
// @ts-ignore
import pie from './pie.svg';
// @ts-ignore
import satelliteA from './satellite_a.svg';
// @ts-ignore
import satelliteB from './satellite_b.svg';
// @ts-ignore
import clock from './clock.svg';

interface Props {
  hovered: boolean;
}

const DigitalTile: FunctionComponent<Props> = ({ hovered }) => {
  return (
    <span className={clsx(styles.wrapper, { [styles.hovered]: hovered })}>
      <img src={satelliteB} alt="" className={styles.satelliteB} />
      <img src={satelliteA} alt="" className={styles.satelliteA} />
      <img src={triangleA} alt="" className={styles.triangleA} />
      <img src={triangleB} alt="" className={styles.triangleB} />
      <img src={pie} alt="" className={styles.pie} />
      <img src={clock} alt="" className={styles.clock} />
    </span>
  );
};

export default DigitalTile;
